.center-margin {
  margin-left: auto;
  margin-right: auto;
}

.center-text {
  text-align: center;
}

.flex-align-center {
  display: flex !important;
  align-items: center;
}

.bottom-margin-2 {
  margin-bottom: 2rem;
}

.bottom-margin-5 {
  margin-bottom: 5rem;
}

.bottom-margin-10 {
  margin-bottom: 10rem;
}

.no-bottom-margin {
  margin-bottom: 0px;
}

.header-with-small {
  align-items: center;
  margin-bottom: 2rem;
  display: flex;
}

.header-with-small small {
  padding-left: 1rem;
}

.inline-radio-button {
  display: flex !important;
  align-items: center;
}

.inline-radio-button input {
  margin-bottom: 0px;
}

.inline-radio-button label {
  padding-left: .5rem;
  margin-bottom: 0px;
}

input#other {
  margin-left: 1rem;
}

button, .button {
  background-color: #0D2281;
  border-color: #0D2281;
}

input:focus {
    border-color: #0D2281 !important;
}

/* nav bar styling */

.form ol {
  margin-top: 1rem;
  display: flex;
  margin-bottom: 1rem;
  justify-content: center;
}

/* mobile */
@media (max-width: 40.0rem) {
    li[class*="go"]::before {
        bottom: -5.8rem;
    }

    li[class*="go"] span {
        float: right;
    }

    li[class$="667"] span {
        position: relative;
        line-height: 5rem;
    }

    div.other {
        max-width: 100% !important;
    }
}

li[class*="go"] {
  border-color: #0D2281;
}

li[class*="go"] span {
  padding-left: 2rem;
}

li[class*="go"]:hover {
  color: #0D2281;
}

/* current */
li[class$="419"]::before {
  background-color: #0D2281;
  line-height: 1.3em;
  color: white;
}

li[class$="419"] span {
  color: #0D2281;
}

/* prev */
li[class$="704"]::before {
  background-color: #0D2281;
  line-height: 1.3em;
  color: white;
}

li[class$="709"] span {
  color: #0D2281;
}


/* next */
li[class$="667"]::before {
  color: silver;
  line-height: 1em;
}

li[class$="667"]:hover::before {
  color: #0D2281;
}

